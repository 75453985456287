.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}
/**/
.sidebarStyle {
    display: inline-block;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
    }
     
    .mapContainer {
    margin: auto;
    width: 100%;
    height: 550px;
      }
    
    .mapmain {
      width: 100%;
    }

    #v1 {
      width:50%;
    }

    
    #v2 {
      width:82%;
    }
    #v3 {
      width:32%;
    }
    #v4 {
      width:20%;
    }
    #v5 {
      width:30%;
    }
    #v6 {
      width:100%;
    }
    #v7 {
      width:50%;
    }

    #v8 {
      width:35%;
    }

    #v9 {
      width:30%;
    }

    #v10 {
      width:20%;
    }
    #v11 {
      width:30%;
    }
    #v12 {
      width:100%;
    }
    #v13 {
      width:50%;
    }
    #v14 {
      width:20%;
    }
    #v15 {
      width:30%;
    }
    #v16 {
      width:100%;
    }
    #v17 {
      width:50%;
    }
    #v18 {
      width:100%;
    }
    #v19 {
      width:50%;
    }
    #v20 {
      width:35%;
    }
    #v21 {
      width:30%;
    }
    #v22 {
      width:20%;
    }
    #v23 {
      width:30%;
    }
    #v24 {
      width:100%;
    }
    #v25 {
      width:50%;
    }
    #v26 {
      width:20%;
    }
    #v27 {
      width:100%;
    }
    #v28 {
      width:30%;
    }
    #right {
      float: right;
      align-self: start;
    
    }
    .bold {
      font-weight: bold;
    }

    .dot {
      height: 10px;
      width: 10px;
      background-color: red;
      border-radius: 50%;
      display: inline-block;
    }
    .guage {
      margin-top: 5%;
    }

    .uprow {
      margin-top: -25%;
    }
    .threeD {
      float: right;
      margin-top: 0%;
      margin-left: 71%;
      position: absolute;
    }
    .borderless td, .borderless th {
      border: none;
  }
  